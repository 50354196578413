import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LipoDetailModel} from "../../models/lipo-detail.model";
import {MatCardModule} from "@angular/material/card";
import {MatTabsModule} from "@angular/material/tabs";
import {LipoDetailTableComponent} from "../lipo-table/lipo-detail-table.component";
import {LipoFormComponent} from "../lipo-form/lipo-form.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Location, NgComponentOutlet, NgIf, NgOptimizedImage, UpperCasePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {LipoConfirmationDialogComponent} from "../lipo-confirmation-dialog/lipo-confirmation-dialog.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {isLipoTableModel, isTypeAny} from "../../../utils/type-check";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {LipoRoutesDataModel} from "../../models/lipo-routes-data.model";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'du-lipo-detail',
  standalone: true,
  imports: [
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    LipoFormComponent,
    LipoDetailTableComponent,
    NgOptimizedImage,
    TranslateModule,
    NgIf,
    NgComponentOutlet,
    UpperCasePipe,
    MatProgressSpinner,
  ],
  templateUrl: './lipo-detail.component.html',
  styleUrl: './lipo-detail.component.scss'
})

export class LipoDetailComponent implements OnInit{
  @Input() detailModel!: LipoDetailModel;
  @Input() navigateBack?: {commands: any[], extras?: NavigationExtras}
  @Input() hideDeleteButton?: boolean = false
  @Input() hideDownloadButton?: boolean = false
  @Input() showDownloadButton: boolean = false
  @Input() showLicenseWizardButton: boolean = false

  @Output() onSaveClicked = new EventEmitter();
  @Output() onDeleteClicked = new EventEmitter();
  @Output() onDownloadClicked = new EventEmitter();
  @Output() onLicenseWizardClicked = new EventEmitter();

  isSaving = false;
  private isCreate: boolean = false;

  constructor(
    private _dialog: MatDialog,
    private _translate: TranslateService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _location: Location,
  ) {
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        let routesDataModel = data as LipoRoutesDataModel;
        this.isCreate = routesDataModel.isCreateItem || false
      }
    })
  }

  isHidden(): boolean | undefined {
    return this.isCreate || this.hideDeleteButton || this.hideDownloadButton;
  }

  getSubtitle(): string {
    if (this.detailModel.subtitle) {
      return this.detailModel.subtitle
    } else {
      return this.detailModel.id ? `#${this.detailModel.id}` : '';
    }
  }

  isFormValid(): boolean {
    let form = this.detailModel.form;
    if (!form) return false;

    return form.formGroup.valid === undefined ? true : form.formGroup.valid;
  }

  onSaveIconClicked(): void {
    if (this.isFormValid()) {
      this.isSaving = true;
      this.onSaveClicked.emit();

      //delay for showing the spinner
      setTimeout(() => {
        this.isSaving = false;
      }, 500);
    }
  }

  onDeleteIconClicked(): void {
    const dialogRef = this._dialog.open(LipoConfirmationDialogComponent, {
      data: {
        title: this._translate.instant('dialog.title.delete'),
        content: this._translate.instant('dialog.content.delete'),
        confirmButtonText: this._translate.instant('button.delete')
      }
    })

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result === true) {
          this.onDeleteClicked.emit();
        }
      }
    });
  }

  onLicenseWizardButtonClicked(): void {
    this.onLicenseWizardClicked.emit();
  }

  onDownloadIconClicked(): void {
    this.onDownloadClicked.emit();
  }

  async handleNavigateBack() {
    if (this.navigateBack) {
      await this._router.navigate(this.navigateBack.commands, this.navigateBack.extras)
    } else {
      this._location.back();
    }
  }

  protected readonly isTypeAny = isTypeAny;
  protected readonly isLipoTableModel = isLipoTableModel;


}
