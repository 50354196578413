<div class="overview-header">
  <div class="overview-header-title">
    <div>
      <h1 class="mat-headline-small overview-title">
        {{ overviewModel.title | translate | titlecase }}
      </h1>
      <p class="mat-body-medium overview-subtitle">
        {{ overviewModel.cards.length }} {{ overviewModel.cards.length | i18nPlural: ('entry_map' | translate) }}
      </p>
    </div>
    <!-- TODO: Implement grid view  -->
    <div class="overview-header-flex-align-center" style="display: none">
      <mat-button-toggle-group class="overview-header-button-toggle dense-3" name="overview-view-type"
                               [multiple]="false"
                               hideSingleSelectionIndicator>
        <mat-button-toggle checked value="grid">
          <mat-icon aria-label="grid"
                    fontIcon="grid_view"></mat-icon>
        </mat-button-toggle>
        <mat-button-toggle disabled value="list">
          <mat-icon aria-label="list"
                    fontIcon="view_list"></mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

  </div>

  <div class="overview-header-filter">
    <div class="overview-header-flex-align-center chip-container">
      <mat-chip-set #chipGrid aria-label="Enter keywords">
        @for (keyword of keywords; track keyword) {
          <mat-chip-row (removed)="removeFilter(keyword)">
            {{ keyword }}
            <button matChipRemove aria-label="'remove ' + keyword">
              <mat-icon>cancel</mat-icon>
            </button>
          </mat-chip-row>
        }
      </mat-chip-set>
      <button class="overview-header-add-filter-button" mat-button disableRipple>
        <mat-icon fontIcon="add"/>
        <span>{{ 'add_filter' | translate }}</span>
      </button>
    </div>
    <div class="overview-header-flex-align-center search-container">
      @for (button of overviewModel.buttons; track $index) {
        <button mat-stroked-button class="overview-header-add-button {{button.className}}"
                (click)="button.onClick()">{{ button.text | translate | uppercase }}
        </button>
      }
      <div class="dense-4">
        <mat-form-field appearance="outline" subscriptSizing="dynamic" color="accent">
          <mat-label>{{ 'search' | translate }}</mat-label>
          <input matInput [(ngModel)]="searchInput">
          <mat-icon matSuffix fontIcon="search"></mat-icon>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>

@if (overviewModel.cards.length > 0) {
  <div class="overview-body">
    <div class="card-container">
      @for (card of overviewModel.cards; track card) {
        <du-lipo-card [content]="card"></du-lipo-card>
      }
    </div>
  </div>
}
