import {Component, inject, OnInit} from '@angular/core';
import {LicenseService} from "../../services/license.service";
import {LicenseServiceModel} from "../../services/models/license-service.model";
import {LicenseOverviewModel} from "../models/license-overview.model";
import moment from "moment";
import {LipoOverviewModel} from "../../../shared/components/models/lipo-overview.model";
import {LipoObjectTypeEnum} from "../../../shared/enums/lipo-object-type.enum";
import {LipoOverviewComponent} from "../../../shared/components/lipo-overview/lipo-overview.component";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {MatDialog} from "@angular/material/dialog";
import {LicenseWizardModel} from "../models/license-wizard.model";
import {LicenseWizardDialogComponent} from "../wizard/license-wizard-dialog.component";

@Component({
  selector: 'du-licence-overview',
  standalone: true,
  imports: [
    LipoOverviewComponent
  ],
  templateUrl: './licence-overview.component.html',
  styleUrl: './licence-overview.component.scss'
})
export class LicenceOverviewComponent implements OnInit {
  licenseOverviewModel?: LipoOverviewModel;
  dialog = inject(MatDialog);

  constructor(
    private _licenseService: LicenseService,
  ) {
  }

  ngOnInit(): void {
    this._licenseService.getAppLicenses().subscribe({
      next: (licenses: LicenseServiceModel[]) => {
        this.processLicenses(licenses);
      },
    });
  }

  private processLicenses(licenses: LicenseServiceModel[]): void {
    const licenseOverviews = licenses.map(license => new LicenseOverviewModel(
      license.id,
      license.product.name,
      license.customer.name,
      moment(license.purchaseDate),
      moment(license.expiryDate),
      license.product.image,
      license.isActive,
      license.isActive ? "active" : "idle"
    ));

    const licenseCards = licenseOverviews.map(license => license.toLipoCardModel());

    this.licenseOverviewModel = {
      title: LipoObjectTypeEnum.LICENCE,
      buttons: LipoButton.build({
        text: "button.license.activate",
        onClick: async () => this.onLicenseWizardClicked()
      }),
      cards: licenseCards,
    };
  }

  onLicenseWizardClicked(): void {
    this.dialog.open(LicenseWizardDialogComponent, {
      data: new LicenseWizardModel({}),
    });
  }

}
