import {Component, OnInit} from '@angular/core';
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {TenantTokenDataService} from "../../services/tenant-token-data.service";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TranslateService} from "@ngx-translate/core";
import {TenantTokenDetailSaveModel} from "../models/tenant-token-detail.model";
import {getTenantTokenDetailFormFields} from "./tenant-token-detail.form-fields";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {TenantTokenSaveDetailMapper} from "../../mappers/tenant-token-detail.mapper";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";

@Component({
  selector: 'du-tenant-token-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './tenant-token-detail.component.html',
  styleUrl: './tenant-token-detail.component.scss'
})
export class TenantTokenDetailComponent implements OnInit {
  protected readonly LipoRouteEnum = LipoRouteEnum;

  detailModel?: LipoDetailModel;
  systemId: number | null = null;
  tenantId: number | null = null;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackbarService,
    private _translationService: TranslateService,
    private _tenantTokenService: TenantTokenDataService,
  ) {
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        this.handleRouteData(data);
      }
    });
  }

  private handleRouteData(data: Data): void {
    const routesDataModel = data as LipoRoutesDataModel;
    this.handleParams();
    this.handleTenantTokenData(routesDataModel);
  }

  private handleParams(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.systemId = this.idToNumber(params.get('systemId'));
      this.tenantId = this.idToNumber(params.get('tenantId'));
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  private handleTenantTokenData(routesDataModel: LipoRoutesDataModel): void {
    if (routesDataModel.isCreateItem) {
      this.createEmptyTenantToken();
    } else {
      this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId]).then(() => this._snackBar.Warning(this._translationService.instant('snackbar.notfound')))
    }
  }

  createEmptyTenantToken(): void {
    let emptyTenantModel = new TenantTokenDetailSaveModel();
    this.createDetail(emptyTenantModel);
  }

  createDetail(tenantTokenModel: TenantTokenDetailSaveModel): void {
    getTenantTokenDetailFormFields(tenantTokenModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel(baseForms)
        this.detailModel = TenantTokenSaveDetailMapper.toLipoDetailModel(tenantTokenModel, lipoFormModel)
      }
    });
  }

  onSaveClick(detailModel: LipoDetailModel): void {
    if (!detailModel.form?.formGroup || !this.systemId || !this.tenantId) return;

    let tenantTokenSaveServiceModel = LipoFormMapper.toTenantTokenSaveServiceModel(detailModel.form?.formGroup)

    this._tenantTokenService.createTenantToken(this.systemId, this.tenantId, tenantTokenSaveServiceModel).subscribe({
      next: _ => {
        this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId]).then(() => this._snackBar.Saved());
      }
    });
  }
}
