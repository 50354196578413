import {asyncScheduler, Observable, scheduled} from "rxjs";
import {
  LipoFormControlModel,
  LipoFormDate,
  LipoFormNumeric,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {AbstractControl, FormControl, Validators} from "@angular/forms";
import {TenantTokenDetailSaveModel} from "../models/tenant-token-detail.model";
import {RegexPatterns} from "../../../utils/regex-patterns";
import moment from "moment";

export function getTenantTokenDetailFormFields(model: TenantTokenDetailSaveModel): Observable<LipoFormControlModel[]> {
  const fields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.name, [Validators.required, Validators.pattern(RegexPatterns.Name)]),
      key: 'name',
      label: 'name',
    }),
    new LipoFormDate({
      value: new FormControl(model.validUntil, [minDateValidator(new Date())]),
      key: 'validUntil',
      label: 'valid.to',
    }),
    new LipoFormNumeric({
      value: new FormControl(model.tokenLength, [Validators.required, Validators.min(1)]),
      key: 'tokenLength',
      label: 'api_token_length',
    })
  ]

  return scheduled([fields], asyncScheduler);
}

function minDateValidator(minDate: Date) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if(control.value === null) {return null;}

    const inputDate = moment(control.value);
    const momentMinDate = moment(minDate).subtract(1, 'd');

    return (momentMinDate.isBefore(inputDate, "d")) ? null : {
      'minDate': {
        value: control.value,
        message: 'error.minDate',
        minDate: moment(minDate).format('DD.MM.YYYY'),
      }
    };
  };
}
