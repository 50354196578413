@if (detailModel) {
  <du-lipo-detail [detailModel]="detailModel"
                  [showDownloadButton]="true"
                  [showLicenseWizardButton]="systemId !== null"
                  (onLicenseWizardClicked)="onLicenseWizardClicked()"
                  (onDeleteClicked)="onDeleteClicked(systemId)"
                  (onSaveClicked)="onSaveClick(detailModel, systemId)"
                  (onDownloadClicked)="onDownloadClick()"
                  [navigateBack]="{commands: [LipoRouteEnum.SYSTEM]}"></du-lipo-detail>
}
