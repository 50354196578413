import {LipoFormControlTypeEnum} from "../../enums/lipo-form-control-type.enum";
import {FormControl} from "@angular/forms";

interface LipoFormControlSettings {
  [key: string]: any;
}

export class LipoFormControlModel {
  value: FormControl;
  key: string;
  label: string;
  order: number;
  controlType: LipoFormControlTypeEnum;
  options: { key: string, value: any }[];
  style: string;
  tooltip: string;
  settings: LipoFormControlSettings;

  constructor(options: {
    value?: FormControl;
    key?: string;
    label?: string;
    order?: number;
    options?: { key: string, value: any }[];
    style?: string;
    tooltip?: string;
    settings?: LipoFormControlSettings;
  } = {}) {
    this.value = options.value ?? new FormControl();
    this.key = options.key ?? '';
    this.label = options.label ?? '';
    this.order = options.order ?? 1;
    this.controlType = LipoFormControlTypeEnum.TEXT;
    this.options = options.options ?? [];
    this.style = options.style ?? '';
    this.tooltip = options.tooltip ?? '';
    this.settings = options.settings ?? {};
  }
}

export class LipoFormTextbox extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.TEXT;

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      maxLength: options.settings?.maxLength ?? 255,
      minLength: options.settings?.minLength ?? 0
    };
  }
}

export class LipoFormPassword extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.PASSWORD;
}

export class LipoFormTextArea extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.TEXTAREA;

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      rows: options.settings?.rows ?? 3
    };
  }
}

export class LipoFormNumeric extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.NUMERIC;

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      min: options.settings?.min ?? 0,
      max: options.settings?.max ?? 100
    };
  }
}

export class LipoFormPhone extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.PHONE;
}

export class LipoFormMail extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.MAIL;
}

export class LipoFormSelect extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.SELECT;

  constructor(options: any = {}) {
    super(options);
    this.settings = {
      ...this.settings,
      multiSelect: options.settings?.multiSelect ?? false
    };
  }
}

export class LipoFormCheckbox extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.CHECKBOX;
}

export class LipoFormRadio extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.RADIO;
}

export class LipoFormDate extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.DATE;
}

export class LipoFormColor extends LipoFormControlModel {
  override controlType = LipoFormControlTypeEnum.COLOR;
}
