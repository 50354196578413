import {Component, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {Observable, of} from "rxjs";
import {FormControl, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PartnerDataService} from "../../services/partner-data.service";
import {PartnerDetailModel} from "../models/partner-detail.model";
import {PartnerServiceMapper} from "../../mappers/partner-service.mapper";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {PartnerDetailMapper} from "../../mappers/partner-detail.mapper";
import {LipoTab} from "../../../shared/components/models/lipoTab";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {CustomerDetailModel} from "../../../customer/components/models/customer-detail.model";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {
  LipoFormControlModel,
  LipoFormMail,
  LipoFormPhone,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {v4 as uuidv4} from "uuid";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {RegexPatterns} from "../../../utils/regex-patterns";

@Component({
  selector: 'du-partner-detail',
  standalone: true,
  imports: [
    LipoDetailComponent,
    MatSnackBarModule
  ],
  templateUrl: './partner-detail.component.html',
  styleUrl: './partner-detail.component.scss'
})
export class PartnerDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  partnerId: number | null = null;

  private readonly _CustomerTableUuid: string = uuidv4();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _partnerService: PartnerDataService,
    private _snackBar: SnackbarService,
  ) {
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        let routesDataModel = data as LipoRoutesDataModel;
        if (routesDataModel.isCreateItem) {
          this.createEmptyPartner();
        } else {
          this._activatedRoute.paramMap.subscribe({
            next: paramMap => {
              let idParam = paramMap.get('id');
              if (!idParam) return;

              let id = +idParam;

              this.partnerId = id
              this.loadPartner(+id);
            }
          })
        }
      }
    })
  }

  loadPartner(id: number): void {
    this._partnerService.getPartner(id).subscribe({
      next: value => {
        let partnerModel = PartnerServiceMapper.toPartnerDetailModel(value)
        this.createDetail(partnerModel);
      }
    })
  }

  createEmptyPartner(): void {
    let emptyPartnerModel = new PartnerDetailModel();
    this.createDetail(emptyPartnerModel);
  }

  createDetail(partnerModel: PartnerDetailModel): void {
    this.getFormFields(partnerModel).subscribe({
      next: baseForms => {
        let tabs = this.getTabs(partnerModel);
        let lipoFormModel = new LipoFormModel(baseForms)
        this.detailModel = PartnerDetailMapper.toLipoDetailModel(partnerModel, lipoFormModel, tabs)
      }
    })
  }

  getFormFields(model: PartnerDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.name, [Validators.required, Validators.pattern(RegexPatterns.Name)]),
        key: 'name',
        label: 'name',
        order: 1
      }),
      new LipoFormMail({
        value: new FormControl(model.mail, [Validators.required, Validators.email, Validators.pattern(RegexPatterns.Email)]),
        key: 'mail',
        label: 'mail',
        order: 2
      }),
      new LipoFormPhone({
        value: new FormControl(model.phone, [Validators.pattern(RegexPatterns.Phone)]),
        key: 'phone',
        label: 'phone',
        order: 3
      }),
    ];
    return of(fields);
  }

  getTabs(model: PartnerDetailModel): LipoTab[] {
    return LipoTab.build(
      {
        title: 'customers',
        components: [
          {
            uuid: this._CustomerTableUuid,
            component: this.getCustomerTable(model.customers)
          }
        ]
      }
    );
  }

  getCustomerTable(customers: CustomerDetailModel[]) {
    let tableDataSource = new MatTableDataSource<CustomerDetailModel>(customers)
    let selectionModel = new SelectionModel<CustomerDetailModel>(true, [])
    let displayedColumns = LipoTableDisplayedColumns.build(
      {HeaderCellName: 'customer', PropertyName: 'name'},
      {HeaderCellName: 'mail', PropertyName: 'mail'},
      {HeaderCellName: 'phone', PropertyName: 'phone'},
    );
    let buttons = LipoButton.build()

    return new LipoTableModel<CustomerDetailModel>(
      tableDataSource,
      selectionModel,
      displayedColumns,
      buttons,
      (value) => this.onRowClick(value)
    )
  }

  async onRowClick(value: LipoModelInterface) {
    let id = value.getId();

    if (id) {
      await this._router.navigate([LipoRouteEnum.CUSTOMER, id]);
    }
  }

  onDeleteClicked(id: number | null): void {
    if (id === null) return;

    this._partnerService.deletePartner(id).subscribe({
      next: (successfully) => {
        if (successfully) {
          this._router.navigate([LipoRouteEnum.PARTNER]).then(() =>  this._snackBar.Deleted());
        }
      }
    })
  }

  onSaveClick(detailModel: LipoDetailModel, id: number | null): void {
    if (!detailModel.form?.formGroup) return;

    let customerTab = detailModel.findComponent<LipoTableModel<CustomerDetailModel>>(this._CustomerTableUuid);

    let servicePartnerModel = LipoFormMapper.toPartnerServiceModel(detailModel.form?.formGroup, null, undefined, customerTab?.tableDataSource.data)

    if (id !== null && id > 0) {
      servicePartnerModel.id = id;
      this._partnerService.updatePartner(servicePartnerModel).subscribe({
        next: partner => {
          this.createDetail(PartnerServiceMapper.toPartnerDetailModel(partner));
          this._snackBar.Saved();
        }
      });
    } else {
      this._partnerService.createPartner(servicePartnerModel).subscribe({
        next: partner => {
          this._router.navigate([LipoRouteEnum.PARTNER, partner.id]).then(() => this._snackBar.Saved());
        }
      });
    }
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
