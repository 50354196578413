export enum LipoObjectTypeEnum {
  PARTNER = 'partner',
  CUSTOMER = 'customer',
  SYSTEM = 'system',
  LICENCE = 'license',
  PRODUCT = 'product',
  PRODUCT_PRICE = 'productPrice',
  TENANT = 'tenant',
  USER = 'user'
}
