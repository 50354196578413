import {Component, inject, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {v4 as uuidv4} from "uuid";
import {ActivatedRoute, Router} from "@angular/router";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {ProductDataService} from "../../services/product-data.service";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {ProductDetailModel} from "../models/product-detail.model";
import {ProductServiceMapper} from "../../mappers/product-service.mapper";
import {Observable, of} from "rxjs";
import {LipoFormControlModel, LipoFormTextbox} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {ProductDetailMapper} from "../../mappers/product-detail.mapper";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {LipoTab} from "../../../shared/components/models/lipoTab";
import {MatTableDataSource} from "@angular/material/table";
import {SelectionModel} from "@angular/cdk/collections";
import {LipoTableDisplayedColumns, LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {DatePipe} from "@angular/common";
import {ProductPriceDetailModel} from "../../../productPrice/components/models/product-price-detail.model";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {MatDialog} from "@angular/material/dialog";
import {LicenseWizardDialogComponent} from "../../../licence/components/wizard/license-wizard-dialog.component";
import {LicenseWizardModel} from "../../../licence/components/models/license-wizard.model";
import {RegexPatterns} from "../../../utils/regex-patterns";

@Component({
  selector: 'du-prodcut-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './product-detail.component.html',
  styleUrl: './product-detail.component.scss'
})
export class ProductDetailComponent implements OnInit {
  detailModel?: LipoDetailModel;
  productId: number | null = null
  partnerId: number | null = null
  dialog = inject(MatDialog);

  protected readonly LipoRouteEnum = LipoRouteEnum;
  private readonly _priceTableUuid: string = uuidv4();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _productService: ProductDataService,
    private _snackBar: SnackbarService,
    private _datePipe: DatePipe,
  ) {
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        let routesDataModel = data as LipoRoutesDataModel;
        if (routesDataModel.isCreateItem) {
          this.createEmptyProduct();
        } else {
          this._activatedRoute.paramMap.subscribe({
            next: paramMap => {
              let idParam = paramMap.get('productId');
              if (!idParam) return;

              let id = +idParam;

              this.productId = id
              this.loadProduct(+id);
            }
          })
        }
      }
    })
  }

  loadProduct(id: number): void {
    this._productService.getProduct(id).subscribe({
      next: value => {
        let productModel = ProductServiceMapper.toProductDetailModel(value)

        this.partnerId = productModel.partnerId ?? null;
        this.createDetail(productModel);
      }
    })
  }

  createEmptyProduct(): void {
    let emptyPartnerModel = new ProductDetailModel();
    this.createSaveDetail(emptyPartnerModel);
  }

  createSaveDetail(productModel: ProductDetailModel): void {
    this.getFormFields(productModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel(baseForms)
        this.detailModel = ProductDetailMapper.toLipoDetailModel(productModel, lipoFormModel)
      }
    })
  }

  createDetail(productModel: ProductDetailModel): void {
    this.getFormFields(productModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel(baseForms)
        this.detailModel = ProductDetailMapper.toLipoDetailModel(productModel, lipoFormModel, this.getTabs(productModel))
      }
    })
  }

  getFormFields(model: ProductDetailModel): Observable<LipoFormControlModel[]> {
    const fields: LipoFormControlModel[] = [
      new LipoFormTextbox({
        value: new FormControl(model.name, [Validators.required, Validators.pattern(RegexPatterns.Name)]),
        key: 'name',
        label: 'name',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.description),
        key: 'description',
        label: 'description',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.image),
        key: 'image',
        label: 'logo_url',
      }),
      new LipoFormTextbox({
        value: new FormControl(model.url),
        key: 'url',
        label: 'product_url',
      }),
    ];
    return of(fields);
  }

  getTabs(model: ProductDetailModel): LipoTab[] {
    return LipoTab.build(
      {
        title: 'prices',
        components: [
          {
            uuid: this._priceTableUuid,
            component: this.getCustomerTable(model.productPrice)
          }
        ]
      }
    );
  }

  getCustomerTable(productPrices: ProductPriceDetailModel[]) {
    return new LipoTableModel<ProductPriceDetailModel>(
      new MatTableDataSource<ProductPriceDetailModel>(productPrices),
      new SelectionModel<ProductPriceDetailModel>(true, []),
      LipoTableDisplayedColumns.build(
        {HeaderCellName: 'id', PropertyName: 'id'},
        {HeaderCellName: 'price', PropertyName: 'price'},
        {HeaderCellName: 'currency', PropertyName: 'currency'},
        {HeaderCellName: 'valid.from', PropertyName: 'validFrom', Pipes: [ this._datePipe ]},
        {HeaderCellName: 'valid.to', PropertyName: 'validTo', Pipes: [ this._datePipe ]},
        {HeaderCellName: 'subscription.type', PropertyName: 'type'},
      ),
      LipoButton.build({
        text: "button.product.price.add",
        onClick: async () => await this._router.navigate([LipoRouteEnum.PRODUCT, this.productId, LipoRouteEnum.PRODUCT_PRICE, 'new'])
      }),
      (value) => this.onRowClick(value)
    )
  }

  async onRowClick(value: LipoModelInterface) {
    let id = value.getId();

    if (id) {
      await this._router.navigate([LipoRouteEnum.PRODUCT, this.productId, LipoRouteEnum.PRODUCT_PRICE, id]);
    }
  }

  onDeleteClicked(id: number | null): void {
    if (id === null) return;

    this._productService.deleteProduct(id).subscribe({
      next: _ => {
        this._router.navigate([LipoRouteEnum.PRODUCT]).then(() => this._snackBar.Deleted());
      }
    })
  }

  onSaveClick(detailModel: LipoDetailModel, id: number | null): void {
    if (!detailModel.form?.formGroup) return;
    let productPriceTab = detailModel.findComponent<LipoTableModel<ProductPriceDetailModel>>(this._priceTableUuid)
    let serviceProductModel = LipoFormMapper.toProductServiceModel(detailModel.form?.formGroup, productPriceTab?.tableDataSource.data);

    if (id !== null && id > 0) {
      serviceProductModel.id = id;
      serviceProductModel.partnerId = this.partnerId!;
      this._productService.updateProduct(serviceProductModel).subscribe({
        next: partner => {
          this.createDetail(ProductServiceMapper.toProductDetailModel(partner));
          this._snackBar.Saved();
        }
      });
    } else {
      this._productService.createProduct(serviceProductModel).subscribe(
        {
          next: product => {
            this._router.navigate([LipoRouteEnum.PRODUCT, product.id]).then(() => this._snackBar.Saved());
          }
        });
    }
  }

  onLicenseWizardClicked(): void {
    this.dialog.open(LicenseWizardDialogComponent, {
      data: new LicenseWizardModel({productId: this.productId}),
    });
  }
}
