import {Component, inject, OnInit} from '@angular/core';
import {LipoDetailComponent} from "../../../shared/components/lipo-detail/lipo-detail.component";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";
import {LipoRoutesDataModel} from "../../../shared/models/lipo-routes-data.model";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {TenantDetailModel} from "../models/tenant-detail.model";
import {LipoFormModel} from "../../../shared/models/lipo-form.model";
import {TenantDetailMapper} from "../../mappers/tenant-detail.mapper";
import {LipoFormMapper} from "../../../shared/mappers/lipo-form.mapper";
import {TenantServiceMapper} from "../../mappers/tenant-service.mapper";
import {TenantDataService} from "../../services/tenant-data.service";
import {LipoRouteEnum} from "../../../shared/enums/lipo-route.enum";
import {SystemDataService} from "../../../system/services/system-data.service";
import {TranslateService} from "@ngx-translate/core";
import {LicenseWizardModel} from "../../../licence/components/models/license-wizard.model";
import {LicenseWizardDialogComponent} from "../../../licence/components/wizard/license-wizard-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {v4 as uuidv4} from "uuid";
import {getTenantDetailFormFields} from "./tenant-detail.form-fields";
import {LipoTab} from "../../../shared/components/models/lipoTab";
import {getAppSettingsTable, getTenantTokenTable, getTenantUserMappingsTable} from "./tenant-detail.table";
import {LipoButton} from "../../../shared/components/models/lipo-button";
import {DatePipe} from "@angular/common";
import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {LipoTableModel} from "../../../shared/components/models/lipo-table.model";
import {TenantUserMappingDetailModel} from "../models/tenant-user-mapping-detail.model";
import {TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";

@Component({
  selector: 'du-tenant-detail',
  standalone: true,
  imports: [
    LipoDetailComponent
  ],
  templateUrl: './tenant-detail.component.html',
  styleUrl: './tenant-detail.component.scss'
})
export class TenantDetailComponent implements OnInit {
  dialog = inject(MatDialog);
  detailModel?: LipoDetailModel;
  backupTenant?: TenantDetailModel;
  systemId: number | null = null;
  tenantId: number | null = null;

  private readonly _appSettingsTableUuid = uuidv4();
  private readonly _userMappingsTableUuid = uuidv4();
  private readonly _tokenTableUuid = uuidv4();

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _snackBar: SnackbarService,
    private _tenantService: TenantDataService,
    private _systemService: SystemDataService,
    private _translationService: TranslateService,
    private _datePipe: DatePipe,
  ) {
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe({
      next: data => {
        this.handleRouteData(data);
      }
    });
  }

  private handleRouteData(data: Data): void {
    const routesDataModel = data as LipoRoutesDataModel;
    this.handleParams();
    this.handleTenantData(routesDataModel);
  }

  private handleParams(): void {
    this._activatedRoute.paramMap.subscribe(params => {
      this.systemId = this.idToNumber(params.get('systemId'));
      this.tenantId = this.idToNumber(params.get('tenantId'));
    });
  }

  private idToNumber(idString: string | null): number | null {
    if (idString === null) {
      return null;
    }
    const num = +idString;
    return isNaN(num) ? null : num;
  }

  private handleTenantData(routesDataModel: LipoRoutesDataModel): void {
    if (routesDataModel.isCreateItem) {
      this.createEmptyTenant();
    } else {
      if (this.systemId !== null) {
        this._systemService.getSystem(this.systemId).subscribe({
          next: value => {
            let tenantServiceModel = value.tenants.find(tenant => tenant.id === this.tenantId)
            if (tenantServiceModel !== undefined) {
              let tenant = TenantServiceMapper.toTenantDetailModel(tenantServiceModel);
              this.backupTenant = tenant;
              this.setTenant(tenant);
            } else {
              this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId]).then(() => this._snackBar.Warning(this._translationService.instant('snackbar.notfound')))
            }
          }
        });
      }
    }
  }

  setTenant(tenant: TenantDetailModel): void {
    this.createDetail(tenant);
  }

  createEmptyTenant(): void {
    let emptyTenantModel = new TenantDetailModel();
    this.createDetail(emptyTenantModel);
  }

  createDetail(tenantModel: TenantDetailModel): void {
    getTenantDetailFormFields(tenantModel).subscribe({
      next: baseForms => {
        let lipoFormModel = new LipoFormModel(baseForms)
        let tabs = this.getTabs(tenantModel)
        this.detailModel = TenantDetailMapper.toLipoDetailModel(tenantModel, lipoFormModel, tabs)
      }
    });
  }

  getTabs(model: TenantDetailModel): LipoTab[] {
    return LipoTab.build(
      {
        title: 'app_settings',
        components: [
          {
            uuid: this._appSettingsTableUuid,
            component: getAppSettingsTable(model.appSettings, this.getAppSettingsTableButtons(), this.onAppSettingsTableRowClick.bind(this)),
          }
        ]
      },
      {
        title: 'user_mappings',
        components: [
          {
            uuid: this._userMappingsTableUuid,
            component: getTenantUserMappingsTable(model.tenantUserMappings, this.getUserMappingsTableButtons(), this.onTenantUserMappingTableRowClick.bind(this))
          }
        ]
      },
      {
        title: 'api_tokens',
        components: [
          {
            uuid: this._tokenTableUuid,
            component: getTenantTokenTable(model.tokens, this._datePipe, this.getTokenTableButtons())
          }
        ]
      },
    );
  }

  async onAppSettingsTableRowClick(value: LipoModelInterface) {
    let appSettingsId = value.getId();

    let appSettingsTable = this.detailModel?.findComponent<LipoTableModel<TenantAppSettingsDetailModel>>(this._appSettingsTableUuid)
    let appSetting = appSettingsTable?.tableDataSource.data.find(data => data.getId() === appSettingsId);

    if (appSetting) {
      await this._router.navigate([
        LipoRouteEnum.SYSTEM,
        this.systemId,
        LipoRouteEnum.TENANT,
        this.tenantId,
        LipoRouteEnum.APP_SETTINGS,
        appSettingsId
      ]);
    }
  }

  async onTenantUserMappingTableRowClick(value: LipoModelInterface) {
    let tenantUserMappingId = value.getId();

    let tenantList = this.detailModel?.findComponent<LipoTableModel<TenantUserMappingDetailModel>>(this._userMappingsTableUuid)
    let tenantUserMapping = tenantList?.tableDataSource.data.find(data => data.getId() === tenantUserMappingId);

    if (tenantUserMapping) {
      await this._router.navigate([
        LipoRouteEnum.SYSTEM,
        this.systemId,
        LipoRouteEnum.TENANT,
        this.tenantId,
        LipoRouteEnum.USER_MAPPING,
        tenantUserMappingId
      ]);
    }
  }

  getAppSettingsTableButtons(): LipoButton[] {
    return LipoButton.build({
      text: "button.app_settings.add",
      onClick: () => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId, LipoRouteEnum.APP_SETTINGS, 'new']),
    });
  }

  getUserMappingsTableButtons(): LipoButton[] {
    return LipoButton.build({
      text: "button.user_mapping.add",
      onClick: () => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId, LipoRouteEnum.USER_MAPPING, 'new']),
    });
  }

  getTokenTableButtons(): LipoButton[] {
    return LipoButton.build({
      text: "button.token.generate",
      onClick: () => this._router.navigate([LipoRouteEnum.SYSTEM, this.systemId, LipoRouteEnum.TENANT, this.tenantId, LipoRouteEnum.TOKEN, 'new']),
    });
  }

  onDeleteClicked(): void {
    if (!this.tenantId || !this.systemId) return;

    this._tenantService.deleteTenant(this.tenantId, this.systemId).subscribe({
      next: (systemModel) => {
        if (systemModel) {
          this._router.navigate([
            LipoRouteEnum.SYSTEM,
            systemModel.id,
          ]).then(() => this._snackBar.Deleted());
        }
      }
    });
  }

  onSaveClick(detailModel: LipoDetailModel): void {
    if (!detailModel.form?.formGroup || !this.systemId) return;

    let serviceTenantModel = LipoFormMapper.toTenantSaveModel(detailModel.form?.formGroup, this.backupTenant)
    let id = this.tenantId;

    if (id !== null && id > 0) {
      this._tenantService.updateTenant(serviceTenantModel, this.systemId).subscribe({
        next: tenant => {
          this.setTenant(TenantServiceMapper.toTenantDetailModel(tenant))
          this._snackBar.Saved();
        }
      });
    } else {
      this._tenantService.createTenant(serviceTenantModel, this.systemId).subscribe({
        next: tenant => {
          const routes = [LipoRouteEnum.SYSTEM, this.systemId];
          if (tenant.id) {
            routes.push(LipoRouteEnum.TENANT, tenant.id);
          }
          this._router.navigate(routes).then(() => this._snackBar.Saved());
        }
      });
    }
  }

  onLicenseWizardClicked(): void {
    this.dialog.open(LicenseWizardDialogComponent, {
      data: new LicenseWizardModel({
        systemId: this.systemId,
        tenantId: this.tenantId
      }),
    });
  }

  protected readonly LipoRouteEnum = LipoRouteEnum;
}
