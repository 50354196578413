import {MobileLogisticsSettingsModel, TenantAppSettingsDetailModel} from "../models/tenant-app-settings-detail.model";
import {
  AppLicenseSettingsConfiguration,
  MobileLogisticSettingsConfiguration,
  SubscriptionType
} from "../../../shared/services/models/configuration.model";
import {asyncScheduler, Observable, scheduled} from "rxjs";
import {
  LipoFormColor,
  LipoFormControlModel,
  LipoFormSelect,
  LipoFormTextArea
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {validColorValidator} from 'ngx-colors';
import {LipoFormGroupModel} from "../../../shared/components/models/lipo-form-group.model";
import {ProductServiceModel} from "../../../product/services/models/product-service.model";
import {LipoDetailModel} from "../../../shared/models/lipo-detail.model";

export function getTenantAppSettingsDetailFormFields(
  model: TenantAppSettingsDetailModel,
  appSettings: AppLicenseSettingsConfiguration,
  products: ProductServiceModel[] = [],
  detailModel: LipoDetailModel,
) {
  let appNameHasValue = model.appName !== null && model.appName !== undefined;
  let appNameControl = new FormControl({value: model.appName, disabled: appNameHasValue}, [Validators.required])

  const appSettingsFields: LipoFormControlModel[] = [
    new LipoFormSelect({
      value: appNameControl,
      key: 'appName',
      label: 'app_name',
      options: products.map(product => ({key: product.name, value: product.name})),
    }),
    new LipoFormTextArea({
      value: new FormControl(model.description),
      key: 'description',
      label: 'description',
    }),
  ]

  let formGroups = [
    new LipoFormGroupModel({key: 'appSettings', label: 'app_settings', order: 1, controls: appSettingsFields}),
  ]

  detailModel.form?.addControlsOrGroups(formGroups)

  if (appNameControl.disabled) {
    handleAppNameControl(appNameControl.value, appSettings, detailModel, model.attributes)
  } else {
    appNameControl.valueChanges?.subscribe({
      next: appNameValue => {
        handleAppNameControl(appNameValue, appSettings, detailModel, model.attributes)
      }
    });
  }
}


export function handleAppNameControl(appNameValue: string | null, appSettings: AppLicenseSettingsConfiguration, detailModel: LipoDetailModel, attributes: any) {
  switch (appNameValue) {
    case appSettings.mobileLogistic.formKey: {
      let mobileLogisticsSettingsModel: MobileLogisticsSettingsModel = new MobileLogisticsSettingsModel(
        typeof (attributes || {})['Policies'] === 'string' ? (attributes['Policies'] as string).split(',') : [],
        typeof (attributes || {})['Modules'] === 'string' ? (attributes['Modules'] as string).split(',') : [],
        (attributes || {})['NavBarColor'] ?? '',
        (attributes || {})['NavBarTextAccentColor'] ?? '',
        (attributes || {})['NavBarTextColor'] ?? ''
      );

      setMobileLogisticSettings(mobileLogisticsSettingsModel, appSettings.mobileLogistic, detailModel);
      break;
    }
  }
}


function setMobileLogisticSettings(mobilesModel: MobileLogisticsSettingsModel, settings: MobileLogisticSettingsConfiguration, detailModel: LipoDetailModel): void {
  getMobileLogisticSettings(mobilesModel, settings.policies, settings.modules).subscribe({
    next: value => {
      if (detailModel) {
        detailModel.form!.addControlsOrGroups(value);
      }
    }
  });
}

export function getMobileLogisticSettings(model: MobileLogisticsSettingsModel, policies: SubscriptionType[] = [], modules: SubscriptionType[] = []): Observable<LipoFormGroupModel[]> {
  const fields: LipoFormControlModel[] = [
    new LipoFormSelect({
      value: new FormControl(model.Policies),
      key: 'Policies',
      label: 'policies',
      options: policies.map(policy => ({key: policy.translation, value: policy.name})),
      settings: {multiSelect: true},
    }),
    new LipoFormSelect({
      value: new FormControl(model.Modules),
      key: 'Modules',
      label: 'modules',
      options: modules.map(modules => ({key: modules.translation, value: modules.name})),
      settings: {multiSelect: true},
    }),
    new LipoFormColor({
      value: new FormControl(model.NavBarColor, [Validators.required, validColorValidator()]),
      key: 'NavBarColor',
      label: 'navBarColor',
    }),
    new LipoFormColor({
      value: new FormControl(model.NavBarTextAccentColor, [Validators.required, validColorValidator()]),
      key: 'NavBarTextAccentColor',
      label: 'navBarTextAccentColor',
    }),
    new LipoFormColor({
      value: new FormControl(model.NavBarTextColor, [Validators.required, validColorValidator()]),
      key: 'NavBarTextColor',
      label: 'navBarTextColor',
    })
  ]

  return scheduled([
    [
      new LipoFormGroupModel({key: 'mobileLogisticSettings', label: 'mobile_settings', order: 2, controls: fields}),
    ]
  ], asyncScheduler);
}
