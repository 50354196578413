import {LipoModelInterface} from "../../../shared/interfaces/lipo-model.interface";
import {TenantDetailModel} from "../../../tenant/components/models/tenant-detail.model";

export class SystemDetailModel implements LipoModelInterface {

  constructor(
    public sapSystemId: string = '',
    public name: string = '',
    public hardwareKey: string | null = null,
    public initialDate: Date,
    public isActive: boolean = false,
    public isActiveText: 'active' | 'idle' = "idle",
    public customer: number | null = null,
    public tenants: TenantDetailModel[] = [],
    public identity: string,
    public logoUrl: string | null = null,
    public id: number | null,
  ) {
  }

  getId(): number | null {
    return this.id;
  }
}

export class SystemSaveDetailModel implements LipoModelInterface {
  constructor(
    public name: string = '',
    public gitlabProjectId: number | null = null,
    public hardwareKey: string | null = null,
    public customer: number | null = null,
    public identity: string = '',
  ) {
  }

  getId(): number | null {
    return this.customer;
  }

}
