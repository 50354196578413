@if (formModel) {
  <form class="form-container" [formGroup]="formModel.formGroup">
    <div class="form-body">

      <div class="form-row" [style]="formModel.style">
        @for (baseForm of formModel.baseForms; track $index) {
          @if (isFormGroup(baseForm)) {
            @let group = formGroup(baseForm);

            <div formGroupName="{{ group.key }}" [style]="group.style">
              <h2 class="mat-title-medium">{{ group.label | translate }}</h2>
              <div class="form-row">
                @for (subForm of getControlsFromFormGroup(baseForm); track $index) {
                  @let controlModel = formControl(subForm);
                  <div class="form-field" [style]="controlModel.style">
                    @if (isOfSpecialType(controlModel!)) {
                      <mat-form-field class="form-field-transparent-background" appearance="fill">
                        <mat-label>{{ controlModel.label | translate }}</mat-label>
                        @if (controlModel.tooltip) {
                          <mat-icon matSuffix matTooltip="{{ controlModel.tooltip | translate }}"
                                    class="tooltip-icon">info
                          </mat-icon>
                        }
                        @switch (controlModel.controlType) {
                          @case (LipoFormControlTypeEnum.TEXT) {
                            <input matInput [formControlName]="controlModel.key"
                                   [minlength]="controlModel.settings['minLength']"
                                   [maxlength]="controlModel.settings['maxLength']"
                            >
                          }
                          @case (LipoFormControlTypeEnum.TEXTAREA) {
                            <textarea matInput [formControlName]="controlModel.key"
                                      [rows]="controlModel.settings['rows']"
                            ></textarea>
                          }
                          @case (LipoFormControlTypeEnum.NUMERIC) {
                            <input matInput type="number" [formControlName]="controlModel.key"
                                   [min]="controlModel.settings['min']"
                                   [max]="controlModel.settings['max']"
                            >
                          }
                          @case (LipoFormControlTypeEnum.MAIL) {
                            <input matInput type="email" [formControlName]="controlModel.key">
                          }
                          @case (LipoFormControlTypeEnum.PHONE) {
                            <input matInput type="tel" [formControlName]="controlModel.key">
                          }
                          @case (LipoFormControlTypeEnum.SELECT) {
                            <mat-select [formControlName]="controlModel.key" [multiple]="controlModel.settings['multiSelect']">
                              @for (option of controlModel.options; track option) {
                                <mat-option [value]="option.value">{{ option.key | translate }}</mat-option>
                              }
                            </mat-select>
                          }
                        }
                        @if (hasError(controlModel!)) {
                          <mat-error>
                            {{ getError(controlModel!) | translate }}
                          </mat-error>
                        }
                      </mat-form-field>
                    }
                    @switch (controlModel.controlType) {
                      @case (LipoFormControlTypeEnum.CHECKBOX) {
                        <mat-checkbox [formControlName]="controlModel.key">{{ controlModel.label | translate }}
                        </mat-checkbox>
                      }
                      @case (LipoFormControlTypeEnum.RADIO) {
                        <mat-radio-group [formControlName]="controlModel.key">
                          @for (option of controlModel.options; track option) {
                            <mat-radio-button [value]="option.value">{{ option.key }}</mat-radio-button>
                          }
                        </mat-radio-group>
                      }
                      @case (LipoFormControlTypeEnum.PASSWORD) {
                        <input matInput type="password" [formControlName]="controlModel.key">
                      }
                      @case (LipoFormControlTypeEnum.DATE) {
                        <mat-form-field class="form-field-transparent-background" appearance="fill">
                          <mat-label>{{ controlModel.label | translate }}</mat-label>
                          <input matInput [matDatepicker]="picker1" [formControlName]="controlModel.key">
                          <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                          <mat-datepicker #picker1></mat-datepicker>
                          @if (hasError(controlModel!)) {
                            <mat-error>
                              {{ getError(controlModel!) | translate }}
                            </mat-error>
                          }
                        </mat-form-field>
                      }
                      @case (LipoFormControlTypeEnum.COLOR) {
                        <mat-form-field floatLabel="always" class="form-field-transparent-background" appearance="fill">
                          <mat-label>{{ controlModel.label | translate }}</mat-label>
                          <input matInput [formControlName]="controlModel.key"
                                 (change)="setColorPicker($event, controlModel!)"/>
                          <ngx-colors
                            matSuffix
                            ngx-colors-trigger
                            [format]="colorFormat"
                            [formControlName]="controlModel.key"
                            (change)="setColorInput($event, controlModel!)"
                          ></ngx-colors>
                        </mat-form-field>
                      }
                    }
                  </div>
                }
              </div>
            </div>
          } @else {
            @let controlModel = formControl(baseForm);
            <div class="form-field" [style]="controlModel.style">
              @if (isOfSpecialType(controlModel!)) {
                <mat-form-field class="form-field-transparent-background" appearance="fill">
                  <mat-label>{{ controlModel.label | translate }}</mat-label>
                  @if (controlModel.tooltip) {
                    <mat-icon matSuffix matTooltip="{{ controlModel.tooltip | translate }}"
                              class="tooltip-icon">info
                    </mat-icon>
                  }
                  @switch (controlModel.controlType) {
                    @case (LipoFormControlTypeEnum.TEXT) {
                      <input matInput [formControlName]="controlModel.key">
                    }
                    @case (LipoFormControlTypeEnum.TEXTAREA) {
                      <textarea matInput [formControlName]="controlModel.key"
                                [rows]="controlModel.settings['rows']"
                      ></textarea>
                    }
                    @case (LipoFormControlTypeEnum.NUMERIC) {
                      <input matInput type="number" [formControlName]="controlModel.key">
                    }
                    @case (LipoFormControlTypeEnum.MAIL) {
                      <input matInput type="email" [formControlName]="controlModel.key">
                    }
                    @case (LipoFormControlTypeEnum.PHONE) {
                      <input matInput type="tel" [formControlName]="controlModel.key">
                    }
                    @case (LipoFormControlTypeEnum.SELECT) {
                      <mat-select [formControlName]="controlModel.key" [multiple]="controlModel.settings['multiSelect']">
                        @for (option of controlModel.options; track option) {
                          <mat-option [value]="option.value">{{ option.key | translate }}</mat-option>
                        }
                      </mat-select>
                    }
                  }
                  @if (hasError(controlModel!)) {
                    <mat-error>
                      {{ getError(controlModel!) | translate }}
                    </mat-error>
                  }
                </mat-form-field>
              }
              @switch (controlModel.controlType) {
                @case (LipoFormControlTypeEnum.CHECKBOX) {
                  <mat-checkbox [formControlName]="controlModel.key">{{ controlModel.label | translate }}</mat-checkbox>
                }
                @case (LipoFormControlTypeEnum.RADIO) {
                  <mat-radio-group [formControlName]="controlModel.key">
                    @for (option of controlModel.options; track option) {
                      <mat-radio-button [value]="option.value">{{ option.key }}</mat-radio-button>
                    }
                  </mat-radio-group>
                }
                @case (LipoFormControlTypeEnum.PASSWORD) {
                  <input matInput type="password" [formControlName]="controlModel.key">
                }
                @case (LipoFormControlTypeEnum.DATE) {
                  <mat-form-field class="form-field-transparent-background" appearance="fill">
                    <mat-label>{{ controlModel.label | translate }}</mat-label>
                    <input matInput [matDatepicker]="picker1" [formControlName]="controlModel.key">
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    @if (hasError(controlModel!)) {
                      <mat-error>
                        {{ getError(controlModel!) | translate }}
                      </mat-error>
                    }
                  </mat-form-field>
                }
                @case (LipoFormControlTypeEnum.COLOR) {
                  <mat-form-field floatLabel="always" class="form-field-transparent-background" appearance="fill">
                    <mat-label>{{ controlModel.label | translate }}</mat-label>
                    <input matInput [formControlName]="controlModel.key"
                           (change)="setColorPicker($event, controlModel!)"/>
                    <ngx-colors
                      matSuffix
                      ngx-colors-trigger
                      [format]="colorFormat"
                      [formControlName]="controlModel.key"
                      (change)="setColorInput($event, controlModel!)"
                    ></ngx-colors>
                  </mat-form-field>
                }
              }
            </div>
          }
        }
      </div>
    </div>
  </form>
}
