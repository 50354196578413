export enum LipoRouteEnum {
  PARTNER = 'partner',
  CUSTOMER = 'customer',
  SYSTEM = 'system',
  LICENCE = 'license',
  ADMIN = 'admin',
  PRODUCT = 'product',
  PRODUCT_PRICE = 'price',
  TENANT = 'tenant',
  USER = 'user',
  TOKEN = 'token',
  USER_MAPPING = 'user-mapping',
  APP_SETTINGS = 'app-settings',
}
