import {Component, Input} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LipoFormControlTypeEnum} from "../../enums/lipo-form-control-type.enum";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {LipoFormModel} from "../../models/lipo-form.model";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatRadioModule} from "@angular/material/radio";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {LipoFormGroupModel} from "../models/lipo-form-group.model";
import {LipoFormControlModel} from "../models/lipo-form-control.model";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIcon} from "@angular/material/icon";
import {NgxColorsModule} from "ngx-colors";

@Component({
  selector: 'du-lipo-form',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    TranslateModule,
    MatDatepickerModule,
    MatTooltip,
    MatIcon,
    NgxColorsModule,
    FormsModule,
  ],
  templateUrl: './lipo-form.component.html',
  styleUrl: './lipo-form.component.scss'
})
export class LipoFormComponent{
  @Input() formModel!: LipoFormModel;

  protected readonly LipoFormControlTypeEnum = LipoFormControlTypeEnum;
  protected readonly colorFormat: string = "hex";

  constructor(
    private _translateService: TranslateService,
  ) {
  }

  isFormGroup(baseForm: any): boolean{
    return baseForm instanceof LipoFormGroupModel;
  }

  formControl(baseForm: any){
    return baseForm as LipoFormControlModel;
  }

  formGroup(baseForm: any){
    return baseForm as LipoFormGroupModel;
  }

  getControlsFromFormGroup(baseForm: LipoFormControlModel | LipoFormGroupModel): LipoFormControlModel[] {
    if (baseForm instanceof LipoFormControlModel) return [];
    return baseForm.controls
  }

  hasError(controlModel: LipoFormControlModel): boolean {
    let control = controlModel.value
    return (control && control.invalid && (control.touched || control.dirty));
  }

  getError(controlModel: LipoFormControlModel): string {
    const control = controlModel.value;
    const controlKey = controlModel.key;

    const hasPatternError = control?.hasError('pattern');
    if (control?.hasError('required')) {
      return 'error.required';
    } else if (hasPatternError) {
      switch (controlModel.controlType) {
        case LipoFormControlTypeEnum.MAIL:
          return 'error.EmailInvalid';
        case LipoFormControlTypeEnum.PHONE:
          return 'error.PhoneInvalid';
        case LipoFormControlTypeEnum.TEXT:
          if (controlKey.includes('name')) {
            return 'error.NameInvalid';
          }
      }
    }

    switch (controlModel.controlType) {
      case LipoFormControlTypeEnum.DATE:
        if (control?.hasError('minDate')) {
          let error = control?.getError('minDate')
          return this._translateService.instant(error?.message, {
            minDate: error?.minDate
          });
        }
    }
    return '';
  }

  isOfSpecialType(subForm:  LipoFormControlModel): boolean {
    return subForm.controlType !== LipoFormControlTypeEnum.CHECKBOX
      && subForm.controlType !== LipoFormControlTypeEnum.RADIO
      && subForm.controlType !== LipoFormControlTypeEnum.DATE
      && subForm.controlType !== LipoFormControlTypeEnum.COLOR
  }

  setColorInput(event: string, controlModel: LipoFormControlModel) {
    controlModel.value.setValue(event)
  }

  setColorPicker(event: Event, controlModel: LipoFormControlModel) {
    const inputElement = event.target as HTMLInputElement;
    controlModel.value.setValue(inputElement.value)
  }
}
