import {SystemDetailModel, SystemSaveDetailModel} from "../models/system-detail.model";
import {asyncScheduler, Observable, scheduled} from "rxjs";
import {
  LipoFormCheckbox,
  LipoFormControlModel,
  LipoFormSelect,
  LipoFormTextbox
} from "../../../shared/components/models/lipo-form-control.model";
import {FormControl, Validators} from "@angular/forms";
import {RegexPatterns} from "../../../utils/regex-patterns";
import {CustomerServiceModel} from "../../../customer/services/models/customer-service.model";

export function getSystemDetailFormFields(model: SystemDetailModel, customers?: CustomerServiceModel[]): Observable<LipoFormControlModel[]> {
  const fields: LipoFormControlModel[] = [
  new LipoFormTextbox({
    value: new FormControl(model.name,[Validators.required, Validators.pattern(RegexPatterns.Name)]),
    key: 'name',
    label: 'name',
  }),
  new LipoFormSelect({
    value: new FormControl(customers?.find(customer => customer.id === model.customer)?.id, Validators.required),
    key: 'customer',
    label: 'customer',
    options: customers?.map(customer => ({key: customer.name, value: customer.id})),
  }),
  new LipoFormTextbox({
    value: new FormControl(model.sapSystemId, Validators.required),
    key: 'sapSystemId',
    label: 'sapSystemId',
    tooltip: 'tooltip.sapSystemId',
  }),
  new LipoFormTextbox({
    value: new FormControl(model.identity, Validators.required),
    key: 'identity',
    label: 'identity',
    tooltip:'tooltip.identity',
  }),
  new LipoFormTextbox({
    value: new FormControl(model.hardwareKey),
    key: 'hardwareKey',
    label: 'hardware_key',
    tooltip:'tooltip.hardwareKey',
  }),
  new LipoFormTextbox({
    value: new FormControl({value: model.initialDate, disabled: true}),
    key: 'initialDate',
    label: 'initial_date',
  }),
  new LipoFormTextbox({
    value: new FormControl(model.logoUrl),
    key: 'logoUrl',
    label: 'logo_url',
  }),
  new LipoFormCheckbox({
    value: new FormControl(model.isActive),
    key: 'isActive',
    label: 'active',
  }),
];
return scheduled([fields], asyncScheduler);
}


export function getSystemSaveDetailFormFields(model: SystemSaveDetailModel, customers?: CustomerServiceModel[]): Observable<LipoFormControlModel[]> {
  const fields: LipoFormControlModel[] = [
    new LipoFormTextbox({
      value: new FormControl(model.name,[Validators.required, Validators.pattern(RegexPatterns.Name)]),
      key: 'name',
      label: 'name',
    }),
    new LipoFormSelect({
      value: new FormControl(customers?.find(customer => customer.id === model.customer)?.id, Validators.required),
      key: 'customer',
      label: 'customer',
      options: customers?.map(customer => ({key: customer.name, value: customer.id})),
    }),
    new LipoFormTextbox({
      value: new FormControl(model.identity, Validators.required),
      key: 'identity',
      label: 'identity',
      tooltip:'tooltip.identity',
    }),
    new LipoFormTextbox({
      value: new FormControl(model.hardwareKey),
      key: 'hardwareKey',
      label: 'hardware_key',
      tooltip:'tooltip.hardwareKey',
    }),
  ];
  return scheduled([fields], asyncScheduler);
}
